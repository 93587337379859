import { useEffect, useState } from "react";
import type { ApolloError } from "@apollo/client";
import { DateTime } from "luxon";

import { notNullOrUndefined, useSiteContext } from "@equiem/lib";

import type {
  BookingPermissionFilters,
  SiteBookingCounts,
  SiteCalendarBookingFragment,
} from "../generated/gateway-client";
import {
  BookingStatus,
  useSiteBookingCountsQuery,
  useSiteBookingsCalendarSingleDayQuery,
} from "../generated/gateway-client";

import type { BookingsFilters } from "./useBookingFilters";

export interface SiteCalendarInterval {
  error: ApolloError | undefined;
  startTime: number;
  endTime: number;
  initialDataLoading: boolean;
  loading: boolean;
  bookings: SiteCalendarBookingFragment[];
  hasMore: boolean;
  fetchMore: () => void;
}

interface Params {
  date: number;
  filters: BookingsFilters;
  permissionFilters: BookingPermissionFilters | null;
  searchText: string | null;
  setCounts: (_counts: SiteBookingCounts | undefined) => void;
  setCountsLoading: (_countsLoading: boolean) => void;
  skip?: boolean;
}

interface IntervalParams {
  startTime: number;
  endTime: number;
  filters: BookingsFilters;
  permissionFilters: BookingPermissionFilters | null;
  searchText: string | null;
  skip?: boolean;
}

const PAGE_SIZE = 30;

function useInterval({
  startTime,
  endTime,
  filters,
  permissionFilters,
  searchText,
  skip,
}: IntervalParams): SiteCalendarInterval {
  const [loadingMore, setLoadingMore] = useState(false);

  const { startDate: _startDate, endDate: _endDate, status: _status, ...applicableFilters } = filters;
  const variables = {
    ...applicableFilters,
    searchText,
    startTime,
    endTime,
    status: [BookingStatus.Approved],
    permissionFilters: permissionFilters ?? {},
    page: { first: PAGE_SIZE },
  };

  const { error, loading, data, fetchMore } = useSiteBookingsCalendarSingleDayQuery({
    variables,
    skip,
    fetchPolicy: "cache-and-network",
  });

  const bookings = data?.siteBookingsList.edges.map((edge) => edge.node).filter(notNullOrUndefined) ?? [];
  const hasMore = data?.siteBookingsList.pageInfo.hasNextPage ?? false;

  const handleFetchMore = () => {
    if (loading || loadingMore || !hasMore) {
      return;
    }

    setLoadingMore(true);
    fetchMore({
      variables: {
        ...variables,
        page: { first: PAGE_SIZE, after: data?.siteBookingsList.pageInfo.endCursor },
      },
      updateQuery(prev, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          siteBookingsList: {
            ...fetchMoreResult.siteBookingsList,
            edges: [...prev.siteBookingsList.edges, ...fetchMoreResult.siteBookingsList.edges],
          },
        };
      },
    })
      .catch((e) => console.error(e))
      .finally(() => setLoadingMore(false));
  };

  return {
    startTime,
    endTime,
    error,
    initialDataLoading: loading,
    loading: loading || loadingMore,
    bookings,
    hasMore,
    fetchMore: handleFetchMore,
  };
}

export function useSingleDayBookingsCalendar({
  date,
  filters,
  permissionFilters,
  searchText,
  setCounts,
  setCountsLoading,
  skip,
}: Params) {
  const { timezone } = useSiteContext();

  const dt = DateTime.fromMillis(date, { zone: timezone }).startOf("day");

  const startOfDay = dt.toMillis();
  const sixAM = dt.set({ hour: 6 }).toMillis();
  const nineAM = dt.set({ hour: 9 }).toMillis();
  const noon = dt.set({ hour: 12 }).toMillis();
  const threePM = dt.set({ hour: 15 }).toMillis();
  const sixPM = dt.set({ hour: 18 }).toMillis();
  const endOfDay = dt.endOf("day").plus({ milliseconds: 1 }).toMillis();

  const { data: countsData, loading: countsLoading } = useSiteBookingCountsQuery({
    variables: {
      ...filters,
      searchText,
      date: startOfDay,
      endDate: endOfDay - 1,
      status: [BookingStatus.Approved],
    },
    fetchPolicy: "cache-and-network",
    skip,
  });
  useEffect(() => {
    if (skip == null || !skip) {
      setCounts(countsData?.siteBookingCounts);
      setCountsLoading(countsLoading);
    }
  }, [countsData?.siteBookingCounts, countsLoading, setCounts, setCountsLoading, skip]);

  const block1 = useInterval({ skip, filters, permissionFilters, searchText, startTime: startOfDay, endTime: sixAM });
  const block2 = useInterval({ skip, filters, permissionFilters, searchText, startTime: sixAM, endTime: nineAM });
  const block3 = useInterval({ skip, filters, permissionFilters, searchText, startTime: nineAM, endTime: noon });
  const block4 = useInterval({ skip, filters, permissionFilters, searchText, startTime: noon, endTime: threePM });
  const block5 = useInterval({ skip, filters, permissionFilters, searchText, startTime: threePM, endTime: sixPM });
  const block6 = useInterval({ skip, filters, permissionFilters, searchText, startTime: sixPM, endTime: endOfDay });

  const blocks = [block1, block2, block3, block4, block5, block6];

  return {
    error: blocks.find((block) => block.error != null)?.error,
    loading: blocks.some((block) => block.initialDataLoading),
    calendar: blocks,
  };
}
