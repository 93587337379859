import { centsToDollar } from "@equiem/lib";
import type {
  BookableResourceSiteAudienceFragmentFragment,
  BookableResourceSiteAudienceInput,
} from "../generated/gateway-client";

export function transformSiteAudiences(
  siteAudiences: BookableResourceSiteAudienceFragmentFragment[],
  currentSiteUuid: string,
): Array<BookableResourceSiteAudienceInput & { siteName: string }> {
  return siteAudiences
    .map((audience) => ({
      site: audience.destination.uuid,
      siteName: audience.destination.name,
      segmentIds: audience.segmentIds,
      segmentSummary: audience.segmentSummary,
      paymentMethods: audience.paymentMethods,
      paymentRateFullDay: audience.paymentRateFullDay != null ? centsToDollar(audience.paymentRateFullDay) : undefined,
      paymentRateHalfDay: audience.paymentRateHalfDay != null ? centsToDollar(audience.paymentRateHalfDay) : undefined,
      paymentRateHourly: audience.paymentRateHourly != null ? centsToDollar(audience.paymentRateHourly) : undefined,
      paymentRateHourlyAfterHours:
        audience.paymentRateHourlyAfterHours != null ? centsToDollar(audience.paymentRateHourlyAfterHours) : undefined,
      paymentRateHourlyWeekend:
        audience.paymentRateHourlyWeekend != null ? centsToDollar(audience.paymentRateHourlyWeekend) : undefined,
      taxExempt: audience.taxExempt,
    }))
    .filter(
      (audience) =>
        audience.site !== currentSiteUuid || (audience.segmentIds != null && audience.segmentIds.length > 0),
    )
    .sort((a, b) => {
      if (a.site === currentSiteUuid && b.site !== currentSiteUuid) {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return -1;
      }
      if (b.site === currentSiteUuid && a.site !== currentSiteUuid) {
        return 1;
      }
      return a.site === b.site
        ? (a.segmentSummary ?? "All").localeCompare(b.segmentSummary ?? "All")
        : a.siteName.localeCompare(b.siteName);
    });
}
