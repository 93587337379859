import React, { useCallback, useState } from "react";
import { Field, useFormikContext } from "formik";
import { Duration } from "luxon";

import { CKEditorNoSSR } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Button, Form, Table, Text, useTheme } from "@equiem/react-admin-ui";
import { RiCloseLine, RiEditLine } from "@equiem/react-admin-ui/icons";

import { BookableResourceCancellationRateType as RateType } from "../../../../../generated/gateway-client";
import { useCurrencyCode } from "../../../../../hooks/useCurrency";
import type { CancellationRate } from "../../../../../lib/cancellationRate";
import type { FormValues } from "../../../../../lib/formValidation";

import { ResourceCreateAndEditFormCancellationPermissionsModal } from "./ResourceCreateAndEditFormCancellationPermissionsModal";
import { ResourceCreateAndEditFormCancellationPermissionsNoticePeriod } from "./ResourceCreateAndEditFormCancellationPermissionsNoticePeriod";

const MAX_RATES_NUMBER = 10;

const MINUTES_IN_HOUR = 60;
const MINUTES_IN_DAY = MINUTES_IN_HOUR * 24;

export const ResourceCreateAndEditFormCancellationPermissions: React.FC = () => {
  const { i18n, t } = useTranslation();
  const fm = useFormikContext<FormValues>();
  const { breakpoints, colors, spacers } = useTheme(true);
  const currency = useCurrencyCode(fm.values.building);

  const [showModal, setShowModal] = useState(false);
  const [modalIndex, setModalIndex] = useState<number | null>(null);

  const addPaymentRateCancellation = useCallback(
    (paymentRateCancellation: CancellationRate) => {
      fm.setFieldValue("paymentRateCancellation", [
        ...fm.values.paymentRateCancellation,
        paymentRateCancellation,
      ]).catch(console.error);

      setShowModal(false);
    },
    [fm],
  );

  const editPaymentRateCancellation = useCallback(
    (paymentRateCancellation: CancellationRate, index: number) => {
      const newPaymentRateCancellation = [...fm.values.paymentRateCancellation];
      newPaymentRateCancellation[index] = paymentRateCancellation;
      fm.setFieldValue("paymentRateCancellation", newPaymentRateCancellation).catch(console.error);

      setShowModal(false);
      setModalIndex(null);
    },
    [fm],
  );

  const removePaymentRateCancellation = useCallback(
    (index: number) => {
      const newPaymentRateCancellation = [...fm.values.paymentRateCancellation];
      newPaymentRateCancellation.splice(index, 1);
      fm.setFieldValue("paymentRateCancellation", newPaymentRateCancellation).catch(console.error);
    },
    [fm],
  );

  return (
    <>
      <Form.Group error={fm.errors.userCanEditBookings}>
        <Field
          name="userCanEditBookings"
          label={t("bookings.resources.usersCanEditCancelTheBooking")}
          description={t("bookings.resources.usersCanEditHint")}
          as={Form.HeroCheckboxOld}
          disabled={fm.isSubmitting}
          value={fm.values.userCanEditBookings}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            fm.setFieldValue("userCanEditBookings", e.target.checked).catch(console.error);
          }}
        />
      </Form.Group>

      {fm.values.userCanEditBookings != null && fm.values.userCanEditBookings && (
        <>
          <div className="notice-period">
            <ResourceCreateAndEditFormCancellationPermissionsNoticePeriod
              name="noticePeriod"
              value={Number(fm.values.editBookingNoticePeriodInMinutes)}
              onChange={(val) => {
                void fm.setFieldValue("editBookingNoticePeriodInMinutes", val);
              }}
              label={t("bookings.resources.noticePeriod")}
              error={fm.errors.editBookingNoticePeriodInMinutes}
              showTooltip={true}
              tooltipText={t("bookings.resources.noticePeriodHint")}
              disabled={fm.isSubmitting}
            />
          </div>

          <Form.Group
            error={fm.errors.editBookingTermsAndConditions}
            label={t("bookings.resources.editCancellationPolicy")}
            showTooltip
            tooltipText={t("bookings.resources.editCancellationPolicyHint")}
          >
            <Field
              id="editBookingTermsAndConditions"
              name="editBookingTermsAndConditions"
              placeholder={t("bookings.resources.termsAndConditions")}
              as={CKEditorNoSSR}
              toolbar={[
                "heading",
                "|",
                "bold",
                "italic",
                "|",
                "link",
                "|",
                "bulletedList",
                "numberedList",
                "|",
                "outdent",
                "indent",
                "|",
                "undo",
                "redo",
              ]}
              disabled={fm.isSubmitting}
            />
          </Form.Group>

          {fm.values.paymentRateCancellation.length > 0 && (
            <Form.Group
              label={t("bookings.resources.cancellationRates")}
              showTooltip
              tooltipText={t("bookings.resources.cancelationRatesHint")}
            >
              <div className="cancellation-rates-container">
                <Table.Table className="w-100">
                  <thead>
                    <tr>
                      <Table.Header label={t("bookings.resources.rateType")} />
                      <Table.Header label={t("common.amount")} />
                      <Table.Header label={t("bookings.resources.noticePeriod")} />
                      <Table.Header label="" />
                      <Table.Header label="" />
                    </tr>
                  </thead>
                  <tbody>
                    {fm.values.paymentRateCancellation.map((paymentRateCancellation, i) => (
                      <tr key={i}>
                        <td>
                          <Text variant="text" component="span" size="small">
                            {paymentRateCancellation.type === RateType.FixedRate
                              ? t("bookings.resources.fixedRate")
                              : t("common.percentage")}
                          </Text>
                        </td>
                        <td>
                          <Text variant="text" component="span" size="small">
                            {paymentRateCancellation.type === RateType.FixedRate
                              ? formatters.currency(Number(paymentRateCancellation.amount), i18n.language, {
                                  currency,
                                })
                              : formatters.percentage(Number(paymentRateCancellation.amount) / 100, i18n.language)}
                          </Text>
                        </td>
                        <td>
                          <Text variant="text" component="span" size="small">
                            {Number(paymentRateCancellation.minutesBefore) < MINUTES_IN_DAY
                              ? formatters.durationshort(Number(paymentRateCancellation.minutesBefore), i18n.language)
                              : Duration.fromObject(
                                  { minutes: Number(paymentRateCancellation.minutesBefore) },
                                  { locale: i18n.language },
                                )
                                  .shiftTo("days")
                                  .toHuman()}
                          </Text>
                        </td>
                        <td>
                          <RiEditLine
                            size="16"
                            color={colors.primary}
                            cursor="pointer"
                            onClick={() => {
                              setModalIndex(i);
                              setShowModal(true);
                            }}
                          />
                        </td>
                        <td>
                          <RiCloseLine
                            size="16"
                            color={colors.danger}
                            cursor="pointer"
                            onClick={() => removePaymentRateCancellation(i)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table.Table>
              </div>
            </Form.Group>
          )}

          {fm.values.paymentRateCancellation.length < MAX_RATES_NUMBER && (
            <Button
              type="button"
              variant="secondary"
              size="lg"
              style={{ width: "100%" }}
              onClick={() => setShowModal(true)}
            >
              {t("bookings.resources.addCancellationRate")}
            </Button>
          )}

          <ResourceCreateAndEditFormCancellationPermissionsModal
            showModal={showModal}
            setShowModal={setShowModal}
            modalIndex={modalIndex}
            setModalIndex={setModalIndex}
            building={fm.values.building}
            usedMinutesBefore={fm.values.paymentRateCancellation.map((prc, i) =>
              prc.minutesBefore !== "" && i !== modalIndex ? prc.minutesBefore : null,
            )}
            paymentRateCancellation={modalIndex != null ? fm.values.paymentRateCancellation[modalIndex] : undefined}
            onAdd={addPaymentRateCancellation}
            onEdit={editPaymentRateCancellation}
          />
        </>
      )}

      <style jsx>
        {`
          .notice-period {
            width: 50%;
            min-width: 200px;
          }
          .form-field-with-units {
            display: flex;
            gap: ${spacers.s2};
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            .notice-period {
              width: 100%;
            }
            .cancellation-rates-container :global(th),
            .cancellation-rates-container :global(td) {
              padding: ${spacers.s3};
            }
          }
        `}
      </style>
    </>
  );
};
