import React, { useCallback, useContext } from "react";

import { formatters, useTranslation } from "@equiem/localisation-eq1";
import {
  RiAddCircleLine,
  RiArrowLeftRightLine,
  RiCalendarCheckFill,
  RiStickyNoteLine,
} from "@equiem/react-admin-ui/icons";
import { DateTime } from "luxon";
import type { CreditTransaction, Maybe, Profile } from "../../../generated/gateway-client";
import { CreditTransactionType } from "../../../generated/gateway-client";
import { useCreditsTypes } from "../hooks/useCreditsTypes";
import RenderPrice from "../utils/RenderPrice";
import { convertCents } from "../utils/convertCents";
import { getTranscationDetailsByGroup } from "../utils/getLatestDate";
import { getTaxValue } from "../utils/getTaxValue";
import { CreditsAmountTransactionDetails } from "./CreditsAmountTransactionDetails";
import { LoadingSkeleton } from "./LoadingSkeleton";
import { CurrentRole, Role, stringNotEmpty } from "@equiem/lib";
import { Dropdown, Text, useTheme } from "@equiem/react-admin-ui";

interface Props {
  credits?: CreditTransaction[];
  loading?: boolean;
  skeletonLoadingItems?: number;
}

const CreditDescription = ({ description }: { description: string }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  return (
    <>
      <div className="credit-description">
        <Dropdown.Icon
          isHoverTrigger
          placement="bottom-start"
          size="sm"
          width={170}
          icon={RiStickyNoteLine}
          hoverIcon={RiStickyNoteLine}
        >
          <div className="py-2 px-4">
            <Text variant="label" color={colors.grayscale[60]}>
              {t("common.description")}
            </Text>
            <div className="mt-2">{description}</div>
          </div>
        </Dropdown.Icon>
      </div>
      <style jsx>{`
        .credit-description {
          display: inline-block;
        }
      `}</style>
    </>
  );
};

export const CreditsTable: React.FC<Props> = ({ credits, loading = false, skeletonLoadingItems = 30 }) => {
  const { i18n } = useTranslation();
  const { currentRole } = useContext(CurrentRole);
  const isFlexManager = currentRole === Role.FlexManager;
  const creditTypes = useCreditsTypes();

  const isActionTypeVisible = useCallback(
    (type?: CreditTransactionType) =>
      [CreditTransactionType.Refund, CreditTransactionType.Charge].includes(type as CreditTransactionType),
    [],
  );

  const displayName = (profile?: Maybe<Profile>) => {
    return `${profile?.firstName ?? ""} ${profile?.lastName ?? ""}`;
  };

  if (loading) {
    return (
      <tr>
        <td colSpan={10}>
          <LoadingSkeleton numberOfLines={skeletonLoadingItems} />
        </td>
      </tr>
    );
  }

  return (
    <>
      {credits?.map((credit) => {
        const taxValue = getTaxValue({
          nettoPrice: credit.purchasedItem?.discountedNetPrice ?? 0,
          taxRateValue: credit.taxRate as number,
        });
        const transactionDetailsByGroup = getTranscationDetailsByGroup(credit.transactionDetails);
        const totalPrice = convertCents((credit.purchasedItem?.discountedNetPrice ?? 0) + taxValue);
        return (
          <tr key={credit.uuid} className="credits-table-row">
            <td className="icon">
              <div className={`icon credit-icon-${credit.type.toLowerCase()}`}>
                {credit.type === CreditTransactionType.Charge && <RiCalendarCheckFill size={16} color="#4F78FC" />}
                {credit.type === CreditTransactionType.Adjustment && <RiArrowLeftRightLine size={16} color="#00843D" />}
                {credit.type === CreditTransactionType.Purchase && <RiAddCircleLine size={16} color="#8A5DFD" />}
                {credit.type === CreditTransactionType.Refund && <RiCalendarCheckFill size={16} color="#4F78FC" />}
              </div>
            </td>
            <td>
              <div className="d-flex flex-column">
                <span>{formatters.dateshort(DateTime.fromMillis(credit.created), i18n.language)}</span>
                <span className="subtext">
                  {formatters.timeshort(DateTime.fromMillis(credit.created), i18n.language)}
                </span>
              </div>
            </td>
            <td>
              <div className="d-flex flex-column">
                <span className="credit-type">
                  {creditTypes[credit.type]}
                  {stringNotEmpty(credit.description) && <CreditDescription description={credit.description} />}
                </span>
                {isActionTypeVisible(credit.type) && <span className="subtext">{credit.booking?.resource.name}</span>}
              </div>
            </td>
            {isFlexManager ? (
              <td>
                <div className="d-flex flex-column">
                  <span>{credit.account.creditCustomer.flexTenant?.name}</span>
                  {credit.type !== CreditTransactionType.Adjustment && (
                    <span className="subtext">{displayName(credit.user.profile)}</span>
                  )}
                </div>
              </td>
            ) : (
              <td>
                <div>{displayName(credit.user.profile)}</div>
              </td>
            )}
            <td>
              <div>
                {credit.transactionDetails.length > 0
                  ? formatters.datemonth(
                      DateTime.fromMillis(
                        transactionDetailsByGroup.latest?.date ??
                          (transactionDetailsByGroup.additional?.date as number),
                      ),
                      i18n.language,
                    )
                  : ""}
              </div>
            </td>
            <td>
              <CreditsAmountTransactionDetails
                amount={convertCents(credit.amount)}
                transactionDetails={credit.transactionDetails}
              />
            </td>
            <td>{convertCents(credit.runningBalance)}</td>
            <td>
              {credit.purchasedItem?.discountedNetPrice != null && (
                <RenderPrice
                  price={convertCents(credit.purchasedItem.discountedNetPrice)}
                  currency={credit.account.creditProvider.currencyCode}
                  className="grayed"
                />
              )}
            </td>
            <td>
              {taxValue > 0 && (
                <RenderPrice
                  price={convertCents(taxValue)}
                  currency={credit.account.creditProvider.currencyCode}
                  className="grayed"
                />
              )}
            </td>
            <td>
              {totalPrice > 0 && (
                <RenderPrice price={totalPrice} currency={credit.account.creditProvider.currencyCode} />
              )}
            </td>

            <style jsx>
              {`
                .credits-table-row :global(.icon) {
                  width: 32px;
                  max-width: 32px;
                }
                .credits-table-row :global(.table) {
                  border-style: hidden;
                  box-shadow: 0 0 0 1px #e6e6e6;
                  border-radius: 8px;
                  border-collapse: collapse;
                  width: 100%;
                  padding-bottom: 64px;
                }
                .credits-table-row :global(.table) th,
                .credits-table-row :global(.table) td {
                  padding: 8px 16px;
                }
                .credits-table-row :global(.table) tr:hover {
                  background: #f2f2f2;
                }
                .subtext {
                  font-size: 12px;
                  line-height: 16px;
                  color: #666666;
                }
                .credits-table-row :global(.grayed) {
                  color: #666666;
                }

                .credit-type {
                  display: inline-flex;
                  align-items: center;
                }
              `}
            </style>
          </tr>
        );
      })}
    </>
  );
};
