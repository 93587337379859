import React, { useContext, useCallback, useEffect, useMemo } from "react";
import { CurrentProfile, stringNotEmpty } from "@equiem/lib";
import { Form, useConfirmer, useToast } from "@equiem/react-admin-ui";
import { useTranslation, useErrorTranslation } from "@equiem/localisation-eq1";
import { useFormikContext } from "formik";
import { DateTime } from "luxon";

import {
  type ResourceFragment as ListResource,
  type BookableResourceFragmentFragment as FullResource,
  type BookingFragmentFragment,
  useBookableResourceLazyQuery,
} from "../../../generated/gateway-client";
import { usePagedResources } from "../../../hooks/usePagedResources";
import type { BookingFormValue } from "../models/BookingFormValue";
import { BookingModal } from "../contexts/BookingModalContext";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { BookingFormContext } from "../contexts/BookingFormProvider";
import { toStartAndEndDate } from "../hooks/useBookResource";
import { dateFormat, timeFormat } from "../libs/formatSelectedTime";
import { getDefaultPaymentMethod } from "../libs/getDefaultPaymentMethod";

interface Props {
  booking: BookingFragmentFragment;
  startDate: number;
  endDate: number;
  disabled: boolean;
  onResourceChange: (newResource: FullResource) => unknown;
}

const MS_PER_MINUTE = 60_000;

const label = (r: ListResource | FullResource, canManageRegion: boolean) =>
  r.building != null ? `${r.name} (${r.building.name}${canManageRegion ? `, ${r.destination.name}` : ""})` : r.name;

// NOTE: This component gets inserted into the modal header, so we don't have
// access to booking form contexts. All required context must be passed as
// props from `useBookingModalEditResourceControl`.
const BookingFormResource: React.FC<Props> = ({ booking, startDate, endDate, disabled, onResourceChange }) => {
  const { t } = useTranslation();
  const { tError } = useErrorTranslation();
  const toast = useToast();
  const { withConfirmation } = useConfirmer();
  const { canManageRegion } = useContext(CurrentProfile);

  const [fetchResourceDetails] = useBookableResourceLazyQuery();

  const { resources, hasMoreData, setSearchText, loading, error } = usePagedResources(
    {
      filters: {
        alternativeResourceForBookingUuid: booking.uuid,
        // resource must be available at the selected time (might have been
        // changed from the booking time)
        startTime: startDate,
        // duration should always be a multiple of 15 minutes, but guarantee
        // it's an integer regardless
        durationMinutes: Math.ceil((endDate - startDate) / MS_PER_MINUTE),
      },
    },
    // search is already debounced by the select control
    { pageSize: 30, searchDebounceMs: 0 },
  );
  useEffect(() => {
    if (error != null) {
      const msg = toast.negative(tError(error));
      return () => msg.remove();
    }
    return () => undefined;
  }, [toast, error, tError]);

  // Guarantee that the booking resource will always be included even when
  // loading/during weirdness. This is important to make sure the select box's
  // value is shown as the resource name.
  const options = useMemo(() => {
    const selectedLabel = label(booking.resource, canManageRegion);
    return [
      {
        value: booking.resource.uuid,
        label: selectedLabel,
        facadeLabel: (
          <div
            title={selectedLabel}
            style={{ whiteSpace: "nowrap", paddingRight: "20px", overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {selectedLabel}
          </div>
        ),
      },
      ...resources
        .filter((r) => r.uuid !== booking.resource.uuid)
        .map((r) => ({ value: r.uuid, label: label(r, canManageRegion) })),
    ];
  }, [resources, booking.resource, canManageRegion]);

  const handleResourceChange = useCallback(
    (newResourceUuid: string | undefined) => {
      if (!stringNotEmpty(newResourceUuid) || newResourceUuid === booking.resource.uuid) {
        return;
      }

      // start fetching the resource early to minimise loading time
      const resultPromise = fetchResourceDetails({ variables: { uuid: newResourceUuid } });

      withConfirmation({
        title: t("bookings.operations.resourceChange"),
        message: t("bookings.operations.resourceChangeMessage"),
        confirmButtonText: t("bookings.operations.resourceChangeConfirm"),
        cancelButtonText: t("common.cancelNo"),
        confirmButtonVariant: "danger",
        size: "md",
        onConfirm: async () => {
          try {
            const result = await resultPromise;
            if (result.error != null || result.data == null) {
              throw result.error ?? new Error(t("common.somethingWrong"));
            }
            await onResourceChange(result.data.bookableResource);
          } catch (err: unknown) {
            toast.negative(tError(err));
          }
        },
        waitForOnConfirm: true,
      })();
    },
    [booking.resource.uuid, fetchResourceDetails, withConfirmation, t, onResourceChange, toast, tError],
  );

  return (
    <>
      <div className="resource-select">
        <Form.DynamicSelect
          className="resource"
          name="resource"
          size="sm"
          search
          title={label(booking.resource, canManageRegion)}
          value={booking.resource.uuid}
          options={options}
          searchPlaceholder={t("bookings.resources.searchForResources")}
          searchCb={setSearchText}
          hasMoreSearchResults={hasMoreData}
          onChange={(e) => handleResourceChange(e.target.value)}
          loading={loading}
          disabled={disabled}
        />
      </div>
      <style jsx>{`
        .resource-select {
          text-transform: none;
        }
      `}</style>
    </>
  );
};

// match the height of the resource selector so the form doesn't jump around
const PaddedResourceName: React.FC<{ name: string }> = ({ name }) => (
  <>
    <div>{name}</div>
    <style jsx>{`
      div {
        line-height: 34px;
      }
    `}</style>
  </>
);

export const useBookingModalEditResourceControl = () => {
  const { setTitleRenderer } = useContext(BookingModal);
  const { booking, timezone, setModalActiveResource } = useContext(BookingModalInfo);
  const info = useContext(BookingFormContext);
  const fm = useFormikContext<BookingFormValue>();

  const canEverEditResource =
    process.env.allowBookingResourceEdit === "true" &&
    booking?.isEditable === true &&
    booking.resource.viewerPermissions?.canManageBookings === true;

  const canCurrentlyEditResource = canEverEditResource && info.step === "initial";

  const { startDate, endDate } = toStartAndEndDate(fm.values, timezone);

  const onResourceChange = useCallback(
    async (resource: FullResource) => {
      // Reset resource-specific field values, then update the resource, then
      // update the date/time field values.
      //
      // This order of operations is important for everything to get initialized
      // correctly for the new resource, especially if it is in a different time
      // zone!
      await fm.setValues((values) => ({ ...values, roomConfiguration: undefined, addOns: [] }));
      setModalActiveResource(resource);
      await fm.setValues((values) => {
        const curr = toStartAndEndDate(values, timezone);
        const newTimezone = resource.building?.timezone ?? resource.destination.timezone;
        return {
          ...values,
          // update the booking times for the new resource's time zone
          date: DateTime.fromMillis(curr.startDate, { zone: newTimezone }).toFormat(dateFormat),
          start: DateTime.fromMillis(curr.startDate, { zone: newTimezone }).toFormat(timeFormat),
          end: DateTime.fromMillis(curr.endDate, { zone: newTimezone }).toFormat(timeFormat),
          // handle the booking moving from a free resource to a paid resource
          freeBookingMode: resource.paymentRateIsFree,
          paymentMethod: getDefaultPaymentMethod(resource, booking),
          // will always be true, but for completeness...
          hasSuperPower: resource.viewerPermissions?.canManageBookings === true,
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [booking, timezone, setModalActiveResource],
  );

  useEffect(() => {
    if (canCurrentlyEditResource) {
      setTitleRenderer(() => (
        <BookingFormResource
          booking={booking}
          startDate={startDate}
          endDate={endDate}
          disabled={fm.isSubmitting}
          onResourceChange={onResourceChange}
        />
      ));
    } else if (canEverEditResource) {
      setTitleRenderer(() => <PaddedResourceName name={booking.resource.name} />);
    } else {
      setTitleRenderer(() => null);
    }
  }, [
    setTitleRenderer,
    booking,
    startDate,
    endDate,
    canCurrentlyEditResource,
    canEverEditResource,
    fm.isSubmitting,
    onResourceChange,
  ]);
};
