import { useCallback, useMemo } from "react";
import { isEqual } from "lodash";
import pickBy from "lodash/pickBy";
import { DateTime } from "luxon";
import type { Runtype } from "runtypes";

import { notNullOrUndefined, stringNotEmpty, useSiteContext } from "@equiem/lib";
import type { TFunction } from "@equiem/localisation-eq1";
import { useTranslation } from "@equiem/localisation-eq1";
import type { FilterItem, FilterValue, FilterValueOptions } from "@equiem/react-admin-ui";
import {
  durationString,
  FilterDateModifier,
  FilterDurationModifier,
  FilterOptionsModifier,
  FilterSelectModifier,
  FilterTextModifier,
  FilterTimeModifier,
  FilterType,
} from "@equiem/react-admin-ui";
import {
  RiBuilding4Line,
  RiDoorOpenLine,
  RiGroupLine,
  RiHomeLine,
  RiInputMethodLine,
  RiMoneyDollarBoxLine,
  RiRepeat2Line,
  RiStore2Line,
  RiTable2,
  RiTeamLine,
  RiText,
  RiTv2Line,
} from "@equiem/react-admin-ui/icons";

import type { BookableResourceFilterOptionsQuery, SiteCompaniesQuery } from "../generated/gateway-client";
import {
  BookingStatus,
  useBookableResourceFilterOptionsQuery,
  useSiteCompaniesQuery,
} from "../generated/gateway-client";
import { resourceFeatureToString, resourceTypeToString } from "../lib/resourceTypeToString";
import type { Capacity } from "../models/CapacityFilter";
import { capacities } from "../models/CapacityFilter";
import { SavedBookingFilters } from "../models/SavedBookingFilters";

import { useLocalStorageState } from "./useLocalStorageState";

type FilterOptionLists = BookableResourceFilterOptionsQuery["bookableResourceFilterOptions"];
type CompanyOptionList = NonNullable<SiteCompaniesQuery["destination"]["companiesV2"]>["edges"];

const MINUTES_IN_HOUR = 60;

export interface BookingsFilters {
  startDate?: number;
  endDate?: number;

  minutesAfterMidnight?: number;
  status?: BookingStatus[];
  minimumDurationMinutes?: number;
  maximumDurationMinutes?: number;
  showPaidBookings?: boolean;

  siteUuid?: string[];
  buildingUuid?: string[];
  levelUuid?: string[];
  resourceName?: string;
  searchText?: string;
  resourceTypeUuid?: string[];
  resourceFeatureUuid?: string[];
  userCompanyUuid?: string[];
  minimumCapacity?: number;
  maximumCapacity?: number;
  resourceOwnerCompanyUuid?: string[];
}

const filterKeys = {
  myBookingsGrid: [
    "site",
    "building",
    "level",
    "resourceOwnerCompany",
    "resourceName",
    "resourceTypeUuid",
    "feature",
    "sharedFacility",
    "date",
    "time",
    "duration",
    "showPaidBookings",
    "capacity",
    "bookingStatus",
  ],
  bookingsManagementList: [
    "site",
    "building",
    "level",
    "userCompany",
    "resourceOwnerCompany",
    "resourceName",
    "resourceTypeUuid",
    "date",
    "time",
    "duration",
    "showPaidBookings",
    "capacity",
    "bookingStatus",
  ],
  bookingsManagementCalendar: [
    "site",
    "building",
    "level",
    "userCompany",
    "resourceOwnerCompany",
    "resourceName",
    "resourceTypeUuid",
    "time",
    "duration",
    "showPaidBookings",
    "capacity",
  ],
} as const;

type View = keyof typeof filterKeys;
type FilterKey = (typeof filterKeys)[View][number] | (typeof filterKeys)[View][number];
type ConcreteFilterValues = Partial<Record<FilterKey, FilterValue>>;

const getSelectValue = <T extends string = string>(v: FilterValue | undefined): T | undefined =>
  v?.type === FilterType.select && stringNotEmpty(v.value) ? (v.value as T) : undefined;
const getSelectBooleanValue = (v: FilterValue | undefined): boolean | undefined =>
  v?.type === FilterType.select && stringNotEmpty(v.value) ? { true: true, false: false }[v.value] : undefined;
const getOptionValue = <T extends string = string>(v: FilterValue | undefined): T[] | undefined =>
  v?.type === FilterType.options && v.value != null && v.value.length > 0
    ? v.value.map(({ value }) => value as T)
    : undefined;
const getTextValue = (v: FilterValue | undefined) =>
  v?.type === FilterType.text && stringNotEmpty(v.value) ? v.value.trim() : undefined;
const getTimeValue = (v: FilterValue | undefined) => {
  if (v?.type === FilterType.time && typeof v.value === "string") {
    const dt = DateTime.fromFormat(v.value, "HH:mm");
    return dt.hour * MINUTES_IN_HOUR + dt.minute;
  }
  return undefined;
};

const getFilters = (
  values: ConcreteFilterValues,
  view: View,
  timezone: string,
  onlyCancelled?: boolean | null,
): BookingsFilters => {
  // ComplexeFilter remembers filters for other views that might not be relevant
  // to this view. That's useful for the user, but we should exclude them from
  // the query filters.
  const viewValues: ConcreteFilterValues = pickBy(values, (_, key) =>
    (filterKeys[view] as ReadonlyArray<string>).includes(key),
  );

  let startDate: number | undefined = undefined;
  let endDate: number | undefined = undefined;

  if (viewValues.date?.type === FilterType.date && viewValues.date.value != null) {
    const [startDateValue, endDateValue] = Array.isArray(viewValues.date.value)
      ? viewValues.date.value
      : ([viewValues.date.value, viewValues.date.value] as const);

    startDate = DateTime.fromFormat(startDateValue, "yyyy-MM-dd", { zone: timezone }).startOf("day").toMillis();
    endDate = DateTime.fromFormat(endDateValue, "yyyy-MM-dd", { zone: timezone }).endOf("day").toMillis();
  }

  const featureValues = getOptionValue(viewValues.feature) ?? [];
  const facilityValues = getOptionValue(viewValues.sharedFacility) ?? [];

  let minimumDurationMinutes: number | undefined = undefined;
  let maximumDurationMinutes: number | undefined = undefined;

  if (viewValues.duration?.type === FilterType.duration && viewValues.duration.value != null) {
    const [minDurationValue, maxDurationValue] = Array.isArray(viewValues.duration.value)
      ? viewValues.duration.value
      : ([viewValues.duration.value, viewValues.duration.value] as const);

    minimumDurationMinutes = durationString.toMinutes(minDurationValue) ?? undefined;
    maximumDurationMinutes = durationString.toMinutes(maxDurationValue) ?? undefined;
  }

  const capacity = getSelectValue<Capacity>(values.capacity);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const capacityFilter = capacity != null ? capacities[capacity].filter ?? {} : {};

  return {
    startDate,
    endDate,
    minutesAfterMidnight: getTimeValue(viewValues.time),
    siteUuid: getOptionValue(viewValues.site),
    buildingUuid: getOptionValue(viewValues.building),
    levelUuid: getOptionValue(viewValues.level),
    userCompanyUuid: getOptionValue(viewValues.userCompany),
    resourceOwnerCompanyUuid: getOptionValue(viewValues.resourceOwnerCompany),
    resourceName: getTextValue(viewValues.resourceName),
    resourceTypeUuid: getOptionValue(viewValues.resourceTypeUuid),
    resourceFeatureUuid:
      featureValues.length === 0 && facilityValues.length === 0 ? undefined : [...featureValues, ...facilityValues],
    minimumDurationMinutes,
    maximumDurationMinutes,
    showPaidBookings: getSelectBooleanValue(viewValues.showPaidBookings),
    status: getOptionValue<BookingStatus>(viewValues.bookingStatus)?.filter(
      (bs) =>
        onlyCancelled !== true ||
        [BookingStatus.Cancelled, BookingStatus.Declined, BookingStatus.PaymentFailed].includes(bs),
    ),
    ...capacityFilter,
  };
};

const toFilterOption = (val: { uuid: string; name: string }, contextTokens: Array<string | undefined> = []) => {
  const context = contextTokens.filter(stringNotEmpty).join(", ");
  return { value: val.uuid, label: `${val.name}${stringNotEmpty(context) ? ` (${context})` : ""}` };
};

const getFilterOptions = (
  filterOptionLists: FilterOptionLists | undefined,
  userCompanyOptionList: CompanyOptionList | undefined,
  currentFilterValues: BookingsFilters,
  t: TFunction,
  view: View,
  onlyCancelled?: boolean | null,
): Partial<Record<FilterKey, FilterItem>> => {
  const siteOptions =
    filterOptionLists?.sites.map((s) => toFilterOption(s)).sort((a, b) => a.label.localeCompare(b.label)) ?? [];
  const singleSite = siteOptions.length === 1 || (currentFilterValues.siteUuid ?? []).length === 1;

  const buildingOptions = (filterOptionLists?.buildings ?? [])
    .filter(
      (b) =>
        (currentFilterValues.siteUuid ?? []).length === 0 ||
        currentFilterValues.siteUuid?.some((s) => b.destination?.uuid === s),
    )
    .map((b) => toFilterOption(b, [!singleSite ? b.destination?.name : undefined]));
  const singleBuilding =
    filterOptionLists?.buildings.length === 1 || (currentFilterValues.buildingUuid ?? []).length === 1;

  const levelOptions = (filterOptionLists?.levels ?? [])
    .filter(
      (l) =>
        (currentFilterValues.siteUuid ?? []).length === 0 ||
        currentFilterValues.siteUuid?.some((s) => l.building.destination?.uuid === s),
    )
    .filter(
      (l) =>
        (currentFilterValues.buildingUuid ?? []).length === 0 ||
        currentFilterValues.buildingUuid?.some((b) => l.building.uuid === b),
    )
    .map((l) =>
      toFilterOption(l, [
        !singleBuilding || !singleSite ? l.building.name : undefined,
        !singleSite ? l.building.destination?.name : undefined,
      ]),
    );

  const resourceOwnerCompanyOptions =
    filterOptionLists?.resourceOwnerCompanies
      .map((c) => toFilterOption(c))
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const featureOptions =
    filterOptionLists?.features
      .map(({ uuid, name }) => ({ uuid, name: resourceFeatureToString(name, t) }))
      .map((val) => toFilterOption(val)) ?? [];

  const sharedFacilityOptions =
    filterOptionLists?.sharedFacilities
      .map(({ uuid, name }) => ({ uuid, name: resourceFeatureToString(name, t) }))
      .map((val) => toFilterOption(val)) ?? [];

  const userCompanyOptions =
    userCompanyOptionList
      ?.map((edge) => edge.node)
      .filter(notNullOrUndefined)
      .map((c) => toFilterOption(c))
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const bookingStatusOptions = [
    { value: BookingStatus.Approved, label: t("bookings.operations.status.approved") },
    { value: BookingStatus.Cancelled, label: t("bookings.reports.cancelled") },
    { value: BookingStatus.Declined, label: t("bookings.operations.status.declined") },
    { value: BookingStatus.PaymentFailed, label: t("bookings.operations.status.paymentFailed") },
    { value: BookingStatus.PendingApproval, label: t("bookings.operations.status.pendingApproval") },
    { value: BookingStatus.PendingPayment, label: t("bookings.operations.status.pendingPayment") },
    {
      value: BookingStatus.PendingWorkplaceManagerApproval,
      label: t("bookings.operations.status.pendingWorkplaceManagerApproval"),
    },
  ]
    .filter(
      (opt) =>
        onlyCancelled !== true ||
        [BookingStatus.Cancelled, BookingStatus.Declined, BookingStatus.PaymentFailed].includes(opt.value),
    )
    .sort((a, b) => a.label.localeCompare(b.label));

  const site: FilterItem = {
    title: t("common.site"),
    icon: RiHomeLine,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: siteOptions.length <= 1,
    options: siteOptions,
  };
  const building: FilterItem = {
    title: t("bookings.resources.resourceBuilding"),
    icon: RiBuilding4Line,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: buildingOptions.length <= 1,
    options: buildingOptions,
  };
  const level: FilterItem = {
    title: t("bookings.resources.resourceLevel"),
    icon: RiTable2,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: levelOptions.length <= 1,
    options: levelOptions,
  };
  const userCompany: FilterItem = {
    title: t("bookings.operations.hostCompany"),
    icon: RiStore2Line,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: userCompanyOptions.length <= 1,
    options: userCompanyOptions,
  };
  const resourceOwnerCompany: FilterItem = {
    title: t("bookings.resources.ownerCompany"),
    icon: RiTeamLine,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: resourceOwnerCompanyOptions.length <= 1,
    options: resourceOwnerCompanyOptions,
  };
  const resourceName: FilterItem = {
    title: t("bookings.resources.resourceName"),
    icon: RiText,
    type: FilterType.text,
    modifiers: [FilterTextModifier.includes],
  };
  const resourceTypeUuid: FilterItem = {
    title: t("bookings.resources.resourceTypeFull"),
    icon: RiInputMethodLine,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: (filterOptionLists?.resourceTypes.length ?? 0) <= 1,
    options: (filterOptionLists?.resourceTypes ?? [])
      .map((rt) => ({
        value: rt.uuid,
        label: resourceTypeToString(rt.name, t),
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  };
  const feature: FilterItem = {
    title: t("bookings.resources.features"),
    icon: RiTv2Line,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: featureOptions.length <= 1,
    options: featureOptions,
  };
  const sharedFacility: FilterItem = {
    title: t("bookings.resources.sharedFacilities"),
    icon: RiDoorOpenLine,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: sharedFacilityOptions.length <= 1,
    options: sharedFacilityOptions,
  };
  const date: FilterItem = {
    title: t("common.date"),
    type: FilterType.date,
    modifiers: [FilterDateModifier.is, FilterDateModifier.between],
  };
  const time: FilterItem = {
    title: t("common.time"),
    type: FilterType.time,
    modifiers: [FilterTimeModifier.is],
  };
  const duration: FilterItem = {
    title: t("bookings.operations.duration"),
    type: FilterType.duration,
    modifiers: [FilterDurationModifier.is, FilterDurationModifier.between],
  };
  const showPaidBookings: FilterItem = {
    title: t("bookings.operations.showPaidBookings"),
    icon: RiMoneyDollarBoxLine,
    type: FilterType.select,
    modifiers: [FilterSelectModifier.is],
    options: [
      { value: "false", label: t("common.free") },
      { value: "true", label: t("bookings.resources.feeApplies") },
    ],
  };
  const capacity: FilterItem = {
    title: t("bookings.resources.resouceCapacity"),
    icon: RiGroupLine,
    type: FilterType.select,
    modifiers: [FilterSelectModifier.is],
    options: Object.values(capacities).map((c) => c.option),
  };
  const bookingStatus: FilterItem = {
    title: t("common.status"),
    icon: RiRepeat2Line,
    type: FilterType.options,
    options: bookingStatusOptions,
    modifiers: [FilterOptionsModifier.includes],
  };

  const items: Record<View, Partial<Record<FilterKey, FilterItem>>> = {
    myBookingsGrid: {
      site,
      building,
      level,
      resourceOwnerCompany,
      resourceName,
      resourceTypeUuid,
      feature,
      sharedFacility,
      date,
      time,
      duration,
      showPaidBookings,
      capacity,
      bookingStatus,
    },
    bookingsManagementList: {
      site,
      building,
      level,
      userCompany,
      resourceOwnerCompany,
      resourceName,
      resourceTypeUuid,
      date,
      time,
      duration,
      showPaidBookings,
      capacity,
      bookingStatus,
    },
    bookingsManagementCalendar: {
      site,
      building,
      level,
      userCompany,
      resourceOwnerCompany,
      resourceName,
      resourceTypeUuid,
      time,
      duration,
      showPaidBookings,
      capacity,
    },
  };

  return items[view];
};

export const useBookingFilters = (view: View, pageName: string, onlyCancelled?: boolean | null) => {
  const currentSite = useSiteContext();
  const { t } = useTranslation();

  const defaultValues = useMemo<Record<string, FilterValue>>(
    () => ({
      date: {
        type: FilterType.date,
        modifier: FilterDateModifier.between,
        value: [
          DateTime.now().setZone(currentSite.timezone).toFormat("yyyy-MM-dd"),
          DateTime.now().setZone(currentSite.timezone).plus({ months: 1 }).toFormat("yyyy-MM-dd"),
        ],
      },
    }),
    [currentSite.timezone],
  );

  const [{ state: filterValuesState, loading: filterValuesLoading }, setFilterValues] = useLocalStorageState<
    Record<string, FilterValue>
  >(`${pageName}-filters`, {}, SavedBookingFilters as Runtype<Record<string, FilterValue>>);

  const filters = useMemo(
    () => getFilters(filterValuesState, view, currentSite.timezone, onlyCancelled),
    [currentSite.timezone, filterValuesState, onlyCancelled, view],
  );

  const { data: filterOptionsData, loading: filterOptionsLoading } = useBookableResourceFilterOptionsQuery({
    variables: { permissionFilters: { canObserveBookings: true } },
    fetchPolicy: "cache-and-network",
  });
  const { data: companiesData, loading: companiesLoading } = useSiteCompaniesQuery({
    variables: { destinationUuid: currentSite.uuid },
  });

  const filterOptions = useMemo<Record<string, FilterItem>>(
    () =>
      getFilterOptions(
        filterOptionsData?.bookableResourceFilterOptions,
        companiesData?.destination.companiesV2?.edges,
        filters,
        t,
        view,
        onlyCancelled,
      ),
    [
      companiesData?.destination.companiesV2?.edges,
      filterOptionsData?.bookableResourceFilterOptions,
      filters,
      onlyCancelled,
      t,
      view,
    ],
  );

  const filterValues = useMemo<Record<string, FilterValue>>(() => {
    const newFilterValues = { ...filterValuesState };

    if (newFilterValues.bookingStatus?.value != null) {
      newFilterValues.bookingStatus.value = (newFilterValues.bookingStatus as FilterValueOptions).value?.filter(
        (bs) =>
          onlyCancelled !== true ||
          [BookingStatus.Cancelled, BookingStatus.Declined, BookingStatus.PaymentFailed].includes(
            bs.value as BookingStatus,
          ),
      );
    }

    return newFilterValues;
  }, [filterValuesState, onlyCancelled]);

  const onFilterChange = useCallback(
    (values: Record<string, FilterValue>): void => {
      if (!filterOptionsLoading && !isEqual(values, filterValuesState)) {
        setFilterValues({ ...values } as ConcreteFilterValues);
      }
    },
    [filterOptionsLoading, filterValuesState, setFilterValues],
  );

  return {
    filtersLoading: filterOptionsLoading || companiesLoading || filterValuesLoading,
    multiSiteUser: (filterOptionsData?.bookableResourceFilterOptions.sites ?? []).length > 1,
    filterOptions,
    defaultValues,
    filterValues,
    filters,
    onFilterChange,
  };
};
