import React from "react";
import type { DurationLike } from "luxon";
import { DateTime } from "luxon";
import { useRouter } from "next/router";

import { useQueryState, useSiteContext } from "@equiem/lib";
import type { TFunction } from "@equiem/localisation-eq1";
import { formatters, useApolloErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import { CalendarNavigation, Form, ProgressCircle, useTheme } from "@equiem/react-admin-ui";

import type { SiteBookingCounts } from "../../../generated/gateway-client";
import { type BookingPermissionFilters, type SiteCalendarBookingFragment } from "../../../generated/gateway-client";
import type { BookingsFilters } from "../../../hooks/useBookingFilters";
import { useMultiDayBookingsCalendar } from "../../../hooks/useMultiDayBookingsCalendar";
import { useSingleDayBookingsCalendar } from "../../../hooks/useSingleDayBookingsCalendar";

import { OperationsCalendarDay } from "./OperationsCalendarDay";
import { OperationsCalendarMonth } from "./OperationsCalendarMonth";
import { OperationsCalendarWeek } from "./OperationsCalendarWeek";

enum Resolution {
  DAY = "1D",
  FIVE_DAYS = "5D",
  SEVEN_DAYS = "7D",
  MONTH = "1M",
}

const isResolution = (r: string): r is Resolution => Object.values(Resolution).includes(r as Resolution);

const resolutionLabel = (resolution: Resolution, t: TFunction): string => {
  switch (resolution) {
    case Resolution.MONTH:
      return t("bookings.operations.calendarView.oneMonth");
    case Resolution.SEVEN_DAYS:
      return t("bookings.operations.calendarView.sevenDays");
    case Resolution.FIVE_DAYS:
      return t("bookings.operations.calendarView.fiveDays");
    case Resolution.DAY:
    default:
      return t("bookings.operations.calendarView.oneDay");
  }
};

const intervalStart = (date: number, resolution: Resolution, timezone: string): number => {
  const dt = DateTime.fromMillis(date, { zone: timezone });
  switch (resolution) {
    case Resolution.MONTH:
      return dt.startOf("month").toMillis();
    case Resolution.SEVEN_DAYS:
    case Resolution.FIVE_DAYS:
      return dt.startOf("week").toMillis();
    case Resolution.DAY:
    default:
      return dt.startOf("day").toMillis();
  }
};

const intervalEnd = (date: number, resolution: Resolution, timezone: string): number => {
  const dt = DateTime.fromMillis(date, { zone: timezone });
  switch (resolution) {
    case Resolution.MONTH:
      return dt.endOf("month").startOf("day").toMillis();
    case Resolution.SEVEN_DAYS:
      return dt.endOf("week").startOf("day").toMillis();
    case Resolution.FIVE_DAYS:
      return dt.plus({ days: 4 }).startOf("day").toMillis();
    case Resolution.DAY:
    default:
      return dt.endOf("day").startOf("day").toMillis();
  }
};

const intervalDuration = (resolution: Resolution): DurationLike => {
  switch (resolution) {
    case Resolution.MONTH:
      return { months: 1 };
    case Resolution.SEVEN_DAYS:
    case Resolution.FIVE_DAYS:
      return { weeks: 1 };
    case Resolution.DAY:
    default:
      return { days: 1 };
  }
};

const intervalHeader = (startDate: number, resolution: Resolution, language: string, timezone: string): string => {
  switch (resolution) {
    case Resolution.MONTH:
      return formatters.datemonth(startDate, language, { timezone });
    case Resolution.SEVEN_DAYS:
    case Resolution.FIVE_DAYS: {
      const endDate = intervalEnd(startDate, resolution, timezone);
      return `${formatters.dateshort(startDate, language, { timezone })} - ${formatters.dateshort(endDate, language, {
        timezone,
      })}`;
    }
    case Resolution.DAY:
    default:
      return formatters.datelong(startDate, language, { timezone });
  }
};

export const OperationsCalendar: React.FC<{
  filters: BookingsFilters;
  permissionFilters: BookingPermissionFilters | null;
  searchText: string | null;
  setCounts: (_counts: SiteBookingCounts | undefined) => void;
  setCountsLoading: (_countsLoading: boolean) => void;
}> = ({ filters, permissionFilters, searchText, setCounts, setCountsLoading }) => {
  const { t, i18n } = useTranslation();
  const { tError } = useApolloErrorTranslation();
  const router = useRouter();
  const { timezone } = useSiteContext();
  const { colors, borderRadius } = useTheme();

  const [{ d: rawDate, res: resolution }, setQueryState] = useQueryState({
    initial: {
      res: Resolution.DAY,
      d: intervalStart(Date.now(), Resolution.DAY, timezone),
    },
    parse: {
      res: (res) => (isResolution(res) ? res : Resolution.DAY),
      d: (d) => {
        const raw = Number(d);
        return Number.isInteger(raw) ? raw : Date.now();
      },
    },
    rememberLastState: true,
  });
  const startDate = intervalStart(rawDate, resolution, timezone);

  const singleDayCalendar = useSingleDayBookingsCalendar({
    date: startDate,
    filters,
    permissionFilters,
    searchText,
    setCounts,
    setCountsLoading,
    skip: resolution !== "1D",
  });
  const multiDayCalendar = useMultiDayBookingsCalendar({
    startDate,
    resolution: resolution === "1D" ? "5D" : resolution,
    filters,
    permissionFilters,
    searchText,
    setCounts,
    setCountsLoading,
    skip: resolution === "1D",
  });

  const error = resolution === "1D" ? singleDayCalendar.error : multiDayCalendar.error;
  const loading = resolution === "1D" ? singleDayCalendar.loading : multiDayCalendar.loading;

  const onResolutionChange = (newResolution: Resolution, date = startDate) => {
    setQueryState({
      d: intervalStart(date, newResolution, timezone),
      res: newResolution,
    });
  };
  const onPrev = () => {
    const newStartDate = DateTime.fromMillis(startDate, { zone: timezone })
      .minus(intervalDuration(resolution))
      .toMillis();
    setQueryState({ d: newStartDate });
  };
  const onNext = () => {
    const newStartDate = DateTime.fromMillis(startDate, { zone: timezone })
      .plus(intervalDuration(resolution))
      .toMillis();
    setQueryState({ d: newStartDate });
  };
  const onReset = () => setQueryState({ d: intervalStart(Date.now(), resolution, timezone) });
  const onBookingClicked = (booking: SiteCalendarBookingFragment) => {
    router.push(`/bookings/operations/${booking.reference}`).catch(console.error);
  };

  return (
    <>
      <div className="container">
        <div className="heading p-5">
          <h1>{intervalHeader(startDate, resolution, i18n.language, timezone)}</h1>
          <div className="controls">
            {loading && <ProgressCircle size="sm" className="calendar-loading mr-4" />}
            <Form.Select
              className="mr-3"
              value={resolution}
              onChange={(e) => onResolutionChange(e.target.value as Resolution)}
            >
              <option value={Resolution.DAY}>{resolutionLabel(Resolution.DAY, t)}</option>
              <option value={Resolution.FIVE_DAYS}>{resolutionLabel(Resolution.FIVE_DAYS, t)}</option>
              <option value={Resolution.SEVEN_DAYS}>{resolutionLabel(Resolution.SEVEN_DAYS, t)}</option>
              <option value={Resolution.MONTH}>{resolutionLabel(Resolution.MONTH, t)}</option>
            </Form.Select>
            <CalendarNavigation prev={onPrev} next={onNext} reset={onReset} />
          </div>
        </div>
        {error != null ? (
          <div className="error-container p-5">{tError(error)}</div>
        ) : (
          <>
            {resolution === "1D" && (
              <OperationsCalendarDay
                loading={loading}
                calendar={singleDayCalendar.calendar}
                onBookingClicked={onBookingClicked}
              />
            )}
            {(resolution === "5D" || resolution === "7D") && (
              <OperationsCalendarWeek
                calendar={multiDayCalendar.calendar}
                onDateClicked={(date) => onResolutionChange(Resolution.DAY, date)}
                onBookingClicked={onBookingClicked}
              />
            )}
            {resolution === "1M" && (
              <OperationsCalendarMonth
                calendar={multiDayCalendar.calendar}
                onDateClicked={(date) => onResolutionChange(Resolution.DAY, date)}
                onBookingClicked={onBookingClicked}
              />
            )}
          </>
        )}
      </div>
      <style jsx>{`
        .container {
          border: 1px solid ${colors.border};
          border-radius: ${borderRadius};
          font-size: 12px;
          line-height: 16px;
        }
        .heading {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .heading h1 {
          font-size: 20px;
          font-weight: bold;
          overflow-wrap: anywhere;
        }
        .controls {
          flex-grow: 1;
          text-align: right;
        }
        .controls :global(select) {
          width: fit-content;
          font-weight: bold;
          text-transform: uppercase;
          color: ${colors.primary};
        }
        .error-container {
          border-top: 1px solid ${colors.border};
        }
      `}</style>
    </>
  );
};
