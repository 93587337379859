import { useTranslation } from "@equiem/localisation-eq1";
import { Skeleton, Text, useTheme } from "@equiem/react-admin-ui";
import React, { useContext } from "react";
import { CurrencyAmount } from "../../../lib/CurrencyAmount";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { RiCoinLine } from "@equiem/react-admin-ui/icons";
import { useBookableResourceProxyBookingRatesQuery } from "../../../generated/gateway-client";
import { CurrentProfile } from "@equiem/lib";

const displayCurrency = (label: string, rate: number, buildingUuid?: string, currencyCode?: string) => {
  return rate > 0 ? (
    <>
      <CurrencyAmount
        currencyCode={currencyCode}
        amount={rate}
        buildingUuid={buildingUuid ?? null}
        className="font-weight-bold"
      />{" "}
      <span>/ {label}</span>
    </>
  ) : null;
};

interface P {
  className?: string;
  displayOne?: boolean;
}
export const BookingResourceRates: React.FC<P> = ({ className, displayOne = false }) => {
  const { t } = useTranslation();
  const { colors, spacers } = useTheme(true);
  const { profile } = useContext(CurrentProfile);
  const { resource, booking, currency } = useContext(BookingModalInfo);

  const { data, loading } = useBookableResourceProxyBookingRatesQuery({
    variables: { resourceUuid: resource.uuid, userUuid: booking?.user.uuid ?? "" },
    skip: booking == null || booking.user.uuid === profile?.uuid,
  });

  const ratesSource = data?.bookableResourceProxyBookingRates ?? resource;
  const rates = [
    { rate: ratesSource.paymentRateHourly, label: t("common.hour") },
    { rate: ratesSource.paymentRateHalfDay, label: t("common.halfDay") },
    { rate: ratesSource.paymentRateFullDay, label: t("common.fullDay") },
    { rate: ratesSource.paymentRateHourlyAfterHours, label: t("common.hourAfterHours") },
    { rate: ratesSource.paymentRateHourlyWeekend, label: t("common.hourWeekend") },
  ];

  const aboveZeroRates = rates.filter((r) => r.rate > 0);
  if (aboveZeroRates.length === 0) {
    return null;
  }

  if (displayOne) {
    return (
      <>
        <div className={`rates-cont ${className}`}>
          <RiCoinLine size={16} color={colors.grayscale[50]} />
          {loading ? (
            <Skeleton.Line dark width="100px" height="16px" />
          ) : (
            <span>
              {t("bookings.resources.from")}{" "}
              {displayCurrency(aboveZeroRates[0].label, aboveZeroRates[0].rate, resource.building?.uuid, currency)}
            </span>
          )}
        </div>
        <style jsx>{`
          .rates-cont {
            display: flex;
            gap: ${spacers.s3};
          }
        `}</style>
      </>
    );
  }

  return (
    <>
      <div className={`rates-cont ${className}`}>
        <Text variant="label" color={colors.grayscale[60]}>
          {t("bookings.resources.rates")}
        </Text>
        {loading ? (
          <Skeleton.Line dark width="100px" height="16px" />
        ) : (
          <div className="rates-body">
            {rates.map((r, index) => (
              <div key={index} className="mr-4">
                {displayCurrency(r.label, r.rate, resource.building?.uuid, currency)}
              </div>
            ))}
          </div>
        )}
      </div>
      <style jsx>{`
        .rates-cont {
          display: flex;
          flex-direction: column;
          gap: ${spacers.s3};
        }
        .rates-body {
          display: flex;
          flex-wrap: wrap;
          font-size: 16px;
          gap: ${spacers.s3};
        }
      `}</style>
    </>
  );
};
