import {
  type BookableResourceFragmentFragment,
  type BookingFragmentFragment,
  BookableResourcePaymentMethod,
} from "../../../generated/gateway-client";

export const getDefaultPaymentMethod = (
  resource: BookableResourceFragmentFragment,
  booking: BookingFragmentFragment | null | undefined,
) => {
  if (booking?.paymentMethod != null) {
    return booking.paymentMethod;
  }
  if (resource.paymentMethods.includes(BookableResourcePaymentMethod.Credits)) {
    return BookableResourcePaymentMethod.Credits;
  }
  if (resource.paymentMethods.length > 0) {
    return resource.paymentMethods[0];
  }
  return undefined;
};
