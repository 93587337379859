import type { FormikErrors } from "formik";

import { centsToDollar, dollarToCents } from "@equiem/lib";

import type { BookableResourceEditQuery, BookableResourceInput } from "../generated/gateway-client";
import { BookableResourceCancellationRateType as RateType } from "../generated/gateway-client";

import { convertInputMoneyToNumber } from "./convertInputMoneyToNumber";

export interface CancellationRate {
  type: RateType;
  amount: string;
  minutesBefore: number | "" | null;
}

export function getCancellationRatesForForm(
  resource: BookableResourceEditQuery["bookableResource"],
): CancellationRate[] {
  return resource.paymentRateCancellation.map(({ type, amount, minutesBefore }) => ({
    type,
    amount: String(type === RateType.Percentage ? Math.round(amount * 100) : centsToDollar(amount)),
    minutesBefore: minutesBefore ?? null,
  }));
}

export function toInputCancellationRates(
  rates: CancellationRate[],
): NonNullable<BookableResourceInput["paymentRateCancellation"]> {
  return rates.map(({ type, amount, minutesBefore }) => ({
    type,
    amount:
      type === RateType.Percentage
        ? Number(amount) / 100
        : dollarToCents(convertInputMoneyToNumber(amount, false)) ?? 0,
    minutesBefore: minutesBefore !== "" ? minutesBefore : null,
  }));
}

export function validateCancellationRates(rates: CancellationRate[]): Array<FormikErrors<CancellationRate>> | null {
  const seenMinutesBefore = new Set<number | null>();

  const errors = toInputCancellationRates(rates).map(({ amount, minutesBefore }) => {
    const rateErrors: FormikErrors<CancellationRate> = {};

    if (Number.isNaN(amount) || amount < 0) {
      rateErrors.amount = "Must be greater than 0";
    }

    if (Number.isNaN(minutesBefore) || (minutesBefore != null && minutesBefore < 0)) {
      rateErrors.minutesBefore = "Must be greater than 0";
    } else if (seenMinutesBefore.has(minutesBefore ?? null)) {
      rateErrors.minutesBefore = "You cannot have multiple rates for the same period";
    }
    seenMinutesBefore.add(minutesBefore ?? null);

    return rateErrors;
  });

  return errors.some((err) => Object.keys(err).length > 0) ? errors : null;
}
