import React, { useContext, useEffect, useMemo } from "react";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";

import { CurrentProfile, CurrentRole, Role, SubTopMenu } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import type { TabItem } from "@equiem/react-admin-ui";
import { ButtonLink, Tabs, useConfirmer, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";
import { RiAddLine, RiArrowLeftLine, RiCloseLine, RiSettings3Line } from "@equiem/react-admin-ui/icons";

import { RequestsMenuContext } from "../contexts/RequestsMenuContext";
import { useQueuesListQuery } from "../generated/requests-client";
import { NewRequestContext } from "../pages/requests/contexts/NewRequestContext";
import { RequestsAccessContext } from "../pages/requests/contexts/RequestsAccessContext";
import { isRequestPageScope, RequestsScopeContext } from "../pages/requests/contexts/RequestsScopeContext";

interface TabProps {
  children: React.ReactNode;
  title?: string;
  heading?: string;
}

export const RequestTabs: React.FC<TabProps> = ({ children, title, heading }) => {
  const { colors, breakpoints } = useTheme(true);
  const { withConfirmation } = useConfirmer();
  const { t } = useTranslation();
  const router = useRouter();
  const { currentRole } = useContext(CurrentRole);
  const { isNewRequest, setIsNewRequest, closeNewRequest } = useContext(NewRequestContext);
  const { currentScope, setScope, setLastVisitedScope, getLastVisitedScope } = useContext(RequestsScopeContext);
  const { profile } = useContext(CurrentProfile);
  const { disabled, activeTabs, closeTab } = useContext(RequestsMenuContext);
  const isMobile = useIsMobileWidth();
  const isRootPage = router.pathname === "/request-management";
  const isNewRequestPage = router.pathname === "/request-management/create-request";
  const access = useContext(RequestsAccessContext);
  const { data: queuesForUserData } = useQueuesListQuery({
    fetchPolicy: "cache-and-network",
  });
  const isRequestManagerFromQueue = useMemo(
    () => queuesForUserData?.reqMgt.queues.some((queue) => queue.viewerRelations.requestManager) ?? false,
    [queuesForUserData],
  );
  const isRequestAssigneeFromQueue = useMemo(
    () => queuesForUserData?.reqMgt.queues.some((queue) => queue.viewerRelations.requestAssignee) ?? false,
    [queuesForUserData],
  );

  const goToKeyRoute = async (pathname: string, preserveQuery = true) => {
    await router.push({
      pathname: pathname,
      query: preserveQuery ? router.query : undefined,
    });
  };

  const defaultTitle = currentRole === Role.Unknown ? t("requests.titleShort") : t("requests.titleLong");

  const tabs = useMemo(() => {
    const defaultTabs: TabItem[] = [];

    // There is a conflict between the workplace manager and request manager tabs, so we need to split them to provide separated views. (FLEX-368)
    if (currentRole === Role.WorkplaceManager && (access.requestManager || isRequestManagerFromQueue)) {
      defaultTabs.push({
        title: t("requests.tab.companyRequests", { company: profile?.companyV2?.name as string }),
        key: "all",
        disabled,
      });
      defaultTabs.push({
        title: t("requests.tab.allRequests"),
        key: "requestManager",
        disabled,
      });
    } else if (
      [Role.WorkplaceManager, Role.PropertyManager, Role.FlexManager].includes(currentRole) ||
      access.requestManager ||
      isRequestManagerFromQueue
    ) {
      defaultTabs.push({
        title: t("requests.tab.allRequests"),
        key: "all",
        disabled,
      });
    }
    if (access.requestAssignee || isRequestAssigneeFromQueue) {
      defaultTabs.push({
        title: t("requests.tab.assignedRequests"),
        key: "assigned",
        disabled,
      });
    }

    defaultTabs.push({
      title: t("requests.tab.myRequests"),
      key: "my",
      disabled,
    });

    const active = isMobile
      ? []
      : activeTabs.map((tab) => ({
          title: tab.reference,
          key: `/request-management/request/${tab.uuid}`,
          disabled,
          onClose: async () => {
            closeTab(tab.uuid);

            if (tab.uuid === router.query.uuid) {
              await goToKeyRoute("/request-management");
            }
          },
        }));

    const newRequestTab =
      isNewRequest && access.requestManager
        ? ([
            {
              title: t("requests.newRequest.title"),
              key: "/request-management/create-request",
              disabled: false,
              onClose: () => {
                withConfirmation({
                  title: t("common.areYouSure"),
                  message: t("requests.newRequest.cancelMessage"),
                  confirmButtonText: t("common.yesCancel"),
                  confirmButtonVariant: "danger",
                  onConfirm: () => {
                    void router.push("/request-management").then(() => {
                      closeNewRequest();
                    });
                  },
                })();
              },
            },
          ] as TabItem[])
        : [];

    return [...defaultTabs, ...active, ...newRequestTab];
  }, [
    currentRole,
    access.requestManager,
    access.requestAssignee,
    t,
    disabled,
    activeTabs,
    profile?.companyV2?.name,
    closeTab,
    router,
    isRequestManagerFromQueue,
    isRequestAssigneeFromQueue,
    isMobile,
    isNewRequest,
  ]);

  const handleTabSelect = async (key: string) => {
    const isScopeTab = isRequestPageScope(key);
    const lastVisitedScope = getLastVisitedScope();

    // if user is on landing page either change scope or go request details page immediately
    if (isRootPage) {
      if (isScopeTab) {
        setScope(key);
      } else {
        await goToKeyRoute(key);
      }

      return;
    }

    if (isScopeTab) {
      // if we are going back from details page to landing page, override last visited scope
      setLastVisitedScope(key);
      // only preserve filters query params if we are returning to the last visited scope
      // (i.e.: My requests -> request details -> click back or close tab or click on "My requests")
      await goToKeyRoute("/request-management", lastVisitedScope === key);
      return;
    }

    // this only happens when we switch between opened request tabs
    await goToKeyRoute(key);
  };

  const showSettings =
    (currentRole === Role.PropertyManager || currentRole === Role.FlexManager) &&
    ((isMobile && isRootPage) || !isMobile);

  useEffect(() => {
    if (!isNewRequest && isNewRequestPage) {
      setIsNewRequest(true);
    }
  }, [isNewRequestPage, isNewRequest]);

  return (
    <>
      <Head>
        <title>{title ?? defaultTitle}</title>
      </Head>
      <div className="cont">
        <SubTopMenu btmPadding={false} topPadding={false} className="top-menu">
          {isMobile && isNewRequestPage && (
            <ButtonLink
              variant="transparent"
              inverted
              className="p-3"
              onClick={() => {
                withConfirmation({
                  title: t("common.areYouSure"),
                  message: t("requests.newRequest.cancelMessage"),
                  confirmButtonText: t("common.yesCancel"),
                  confirmButtonVariant: "danger",
                  onConfirm: () => {
                    void router.push("/request-management").then(() => {
                      closeNewRequest();
                    });
                  },
                })();
              }}
            >
              <RiCloseLine size={20} color={colors.grayscale[60]} />
            </ButtonLink>
          )}
          {isMobile && !isRootPage && !isNewRequestPage && (
            <Link
              href={{
                pathname: "/request-management",
                query: router.query,
              }}
              passHref
              legacyBehavior
            >
              <a className="mr-3">
                <RiArrowLeftLine size={16} color={colors.grayscale[60]} />
              </a>
            </Link>
          )}
          <h1 className="mb-3 font-weight-bold">{heading ?? defaultTitle}</h1>
          {showSettings && (
            <div className="actions d-flex">
              <Link
                href={{
                  pathname: "/request-management/settings",
                  query: router.query,
                }}
                passHref
                legacyBehavior
              >
                <ButtonLink variant="ghost" size="md">
                  <RiSettings3Line size={16} />
                  {!isMobile && t("common.settings")}
                </ButtonLink>
              </Link>
            </div>
          )}
        </SubTopMenu>
        {(!isMobile || isRootPage || (!isMobile && isNewRequestPage)) && (
          <SubTopMenu btmPadding={false} topPadding={false} minHeight={false} alignItems="flex-end" sticky>
            <Tabs
              items={tabs}
              selected={isRootPage ? currentScope : router.asPath}
              tabLinks
              preventPropagateClick
              onSelect={(key) => {
                handleTabSelect(key).catch(console.log);
              }}
              actionComponent={
                access.requestManager ? (
                  <Link href="/request-management/create-request" passHref legacyBehavior>
                    <ButtonLink
                      disabled={isNewRequest || !access.hasActiveQueueForRequestManager}
                      size="md"
                      variant="secondary"
                      onClick={() => setIsNewRequest(true)}
                      className="create-button"
                    >
                      <RiAddLine size={16} />
                      {t("requests.newRequest.title")}
                    </ButtonLink>
                  </Link>
                ) : null
              }
            />
          </SubTopMenu>
        )}
        {children}
      </div>
      <style jsx>{`
        .cont {
          background: ${colors.white};
          min-height: calc(100vh - 65px);
        }

        @media (max-width: ${breakpoints.lg}px) {
          .cont h1 {
            margin-bottom: 0 !important;
          }
          .cont :global(.top-menu) {
            width: 100%;
            justify-content: flex-start !important;
          }
          .cont .actions {
            margin-left: auto;
          }
        }
      `}</style>
    </>
  );
};
