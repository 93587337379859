import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";

import { useDisabledSiteSwitcher, useQueryState, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import type { RadioIconOption } from "@equiem/react-admin-ui";
import { Button, RadioIcons, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiCalendar2Line, RiListCheck } from "@equiem/react-admin-ui/icons";

import { BookingsTab } from "../../components/BookingsTab";
import type { SiteBookingCounts } from "../../generated/gateway-client";
import { useSiteBookingCountsQuery } from "../../generated/gateway-client";
import { useBookingFilters } from "../../hooks/useBookingFilters";
import { usePagedSiteBookings } from "../../hooks/usePagedSiteBookings";
import { BookingsView } from "../../models/BookingsView";

import { OperationsCalendar } from "./components/OperationsCalendar";
import { OperationsList } from "./components/OperationsList";
import { usePrintableBookings } from "./hooks/usePrintableBookings";
import type { Tab } from "./BookingsManagementFilterTabs";
import { BookingsManagementFilterTabs, defaultTab, isTab, tabFilters } from "./BookingsManagementFilterTabs";

const isView = (v: unknown): v is BookingsView => Object.values(BookingsView).includes(v as BookingsView);

export const BookingsManagement: React.FC = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const { timezone } = useSiteContext();
  const { breakpoints, spacers } = useTheme();

  const [{ view, tab }, setQueryState] = useQueryState({
    initial: { view: BookingsView.BOOKINGS_MANAGEMENT_LIST, tab: defaultTab },
    parse: {
      view: (v) => (isView(v) ? v : BookingsView.BOOKINGS_MANAGEMENT_LIST),
      tab: (f) => (isTab(f) ? f : defaultTab),
    },
    rememberLastState: true,
  });

  useDisabledSiteSwitcher();

  const viewOptions: Array<RadioIconOption<BookingsView>> = [
    {
      icon: RiListCheck,
      value: BookingsView.BOOKINGS_MANAGEMENT_LIST,
      className: BookingsView.BOOKINGS_MANAGEMENT_LIST,
      tooltipText: t("bookings.operations.list"),
    },
    {
      icon: RiCalendar2Line,
      value: BookingsView.BOOKINGS_MANAGEMENT_CALENDAR,
      className: BookingsView.BOOKINGS_MANAGEMENT_CALENDAR,
      tooltipText: t("bookings.operations.calendar"),
    },
  ];

  const { filtersLoading, multiSiteUser, filterOptions, defaultValues, filterValues, filters, onFilterChange } =
    useBookingFilters(view, "bookings-management");
  const complexFilters = useMemo(
    () => ({
      loading: filtersLoading,
      items: filterOptions,
      initialValues: filterValues,
      defaultValues,
      setValues: onFilterChange,
    }),
    [defaultValues, filterValues, filtersLoading, filterOptions, onFilterChange],
  );

  const {
    bookings,
    error,
    searchTextRaw,
    setSearchTextRaw,
    searchText,
    hasMoreData,
    loading,
    fetchMore,
    fetchAll,
    refetch,
  } = usePagedSiteBookings(filters, tabFilters[tab], view !== BookingsView.BOOKINGS_MANAGEMENT_LIST);
  const { data: countsData, loading: countsDataLoading } = useSiteBookingCountsQuery({
    variables: {
      date: filters.startDate ?? DateTime.now().setZone(timezone).startOf("day").toMillis(),
      ...filters,
      searchText,
    },
    fetchPolicy: "cache-and-network",
    skip: view !== BookingsView.BOOKINGS_MANAGEMENT_LIST,
  });
  const [counts, setCounts] = useState<SiteBookingCounts>();
  const [countsLoading, setCountsLoading] = useState<boolean>(false);
  useEffect(() => {
    if (view === BookingsView.BOOKINGS_MANAGEMENT_LIST) {
      setCounts(countsData?.siteBookingCounts);
      setCountsLoading(countsDataLoading);
    }
  }, [countsData?.siteBookingCounts, countsDataLoading, view]);

  const [isPrinting, setIsPrinting] = useState(false);
  const { handlePrint, renderPrintTable } = usePrintableBookings();
  const onPrintClicked = async () => {
    if (isPrinting) {
      return;
    }

    setIsPrinting(true);
    try {
      const bookingsToPrint = await fetchAll();
      if (bookingsToPrint != null) {
        handlePrint(bookingsToPrint);
      }
    } catch (e: unknown) {
      console.error(e);
      toast.negative(t("common.unknownError"));
    } finally {
      setIsPrinting(false);
    }
  };

  const handleViewChange = useCallback(
    (newView: BookingsView) => {
      if (newView !== view) {
        setQueryState({ view: newView });
        if (newView === BookingsView.BOOKINGS_MANAGEMENT_LIST) {
          void refetch();
        }
      }
    },
    [refetch, setQueryState, view],
  );

  const handleTabFilterChange = useCallback(
    (newTab: Tab) => {
      if (newTab !== tab) {
        setQueryState({ tab: newTab });
      }
    },
    [setQueryState, tab],
  );

  return (
    <>
      <BookingsTab
        title={
          <div className="title-container">
            <RadioIcons options={viewOptions} value={view} onChange={handleViewChange} />

            <BookingsManagementFilterTabs
              onTabChanged={handleTabFilterChange}
              activeTab={tab}
              countLoading={countsLoading}
              totalCount={counts?.all}
              myResourcesCount={counts?.myResources}
              myEmployeesCount={counts?.myEmployees}
            />
          </div>
        }
        search={{ searchText: searchTextRaw, setSearchText: setSearchTextRaw }}
        filters={complexFilters}
        button={
          view === BookingsView.BOOKINGS_MANAGEMENT_LIST && (
            <Button
              className="main-button"
              variant="primary"
              size="md"
              onClick={() => {
                void onPrintClicked();
              }}
            >
              {t("bookings.operations.printRunSheet")}
            </Button>
          )
        }
      >
        <div className="operations-list">
          {view === BookingsView.BOOKINGS_MANAGEMENT_LIST && (
            <OperationsList
              bookings={bookings}
              error={error}
              hasMoreData={hasMoreData}
              loading={loading}
              fetchMore={fetchMore}
              fetchAll={fetchAll}
              multiSiteUser={multiSiteUser}
              isPrinting={isPrinting}
            />
          )}
          {view === BookingsView.BOOKINGS_MANAGEMENT_CALENDAR && (
            <OperationsCalendar
              filters={filters}
              permissionFilters={tabFilters[tab]}
              searchText={searchText}
              setCounts={setCounts}
              setCountsLoading={setCountsLoading}
            />
          )}
        </div>
      </BookingsTab>
      {renderPrintTable()}
      <style jsx>{`
        .title-container {
          display: flex;
          gap: ${spacers.s5};
          align-items: center;
        }
        .main-button {
          text-transform: uppercase;
        }
        .operations-list {
          padding: ${spacers.s0} ${spacers.s7} ${spacers.s5};
        }
        @media screen and (max-width: ${breakpoints.sm}px) {
          .operations-list {
            padding: ${spacers.s0} ${spacers.s5} ${spacers.s5};
          }
        }
      `}</style>
    </>
  );
};
